import React from 'react'

export default (props) => (
	<svg viewBox={'0 0 35 35'} xmlns="http://www.w3.org/2000/svg">
		<g
			stroke={
				props.color
					? props.color
					: props.winter
					? '#005394'
					: 'rgba(234, 134, 45, 1)'
			}
			fill="none"
			fillRule="evenodd"
		>
			<path d="M17.5 1C8.4 1 1 8.4 1 17.5S8.4 34 17.5 34 34 26.6 34 17.5 26.6 1 17.5 1zm0 31.9C9 32.9 2.1 26 2.1 17.5S9 2.1 17.5 2.1 32.9 9 32.9 17.5 26 32.9 17.5 32.9z" />
			<path d="M16.11 8a.39.39 0 0 0-.39.38v8.85H9.9a.39.39 0 0 0-.39.39c0 .2.17.38.39.38h6.22c.22 0 .39-.17.39-.38V8.38a.39.39 0 0 0-.39-.38z" />
		</g>
	</svg>
)
