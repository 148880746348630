import React, { useContext } from 'react'
import { HeaderContext } from '../../components/Header/Header'

export default ({ text }) => {
	const HeaderCTX = useContext(HeaderContext)
	const winter = HeaderCTX.winterTime
	return text && Boolean(text.length) ? (
		<svg viewBox={'0 0 36 35'} xmlns="http://www.w3.org/2000/svg">
			<g fill="none" fillRule="evenodd">
				<text
					fontSize="14"
					letterSpacing=".67"
					fill={winter ? '#005394' : 'rgba(234, 134, 45, 1)'}
					transform="translate(1 1)"
				>
					<tspan x={text.length >= 2 ? '8' : '12'} y="21">
						{text} +
					</tspan>
				</text>
				<path
					d="M29.62 6.31A16.46 16.46 0 0 0 17.5 1C8.4 1 1 8.4 1 17.5S8.4 34 17.5 34a16.5 16.5 0 0 0 13.8-7.47"
					stroke={winter ? '#005394' : 'rgba(234, 134, 45, 1)'}
					strokeWidth="2"
				/>
			</g>
		</svg>
	) : null
}
