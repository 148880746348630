import React from 'react'
import './Specifications.scss'
import Specification from '../Specification/Specification'

export default ({ specifications, extraClass }) => {
	return (
		<ul
			className={`specification__list${
				extraClass ? ` ${extraClass}` : ''
			}`}
		>
			{specifications.length
				? specifications.map((specification, i) => {
						if (!specification.label) return null
						return (
							<Specification
								key={`${specification.icon} - ${i}`}
								label={specification.label}
								icon={specification.icon}
								keyValue={specification.label}
							/>
						)
				  })
				: null}
		</ul>
	)
}
