import React, { useContext, useState, useRef, useEffect } from 'react'
import Specifications from '../../Specifications/Specifications'
import Clock from '../../../images/icons/Clock'
import Eur from '../../../images/icons/Eur'
import './Expedition.scss'
import Button from '../../Button/Button'
import { HeaderContext } from '../../Header/Header'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import { phoneNumberTransformer } from '../../../utils/phoneNumberTransformer'
import { convertDate } from '../../../utils/dateToDDMMYYY'
import SignaturePad from 'signature_pad'

export default (props) => {
	const { courseData } = props
	const HeaderCTX = useContext(HeaderContext)
	const [formSent, setFormSent] = useState(false)
	const canvasRef = useRef(null)
	const signaturePad = useRef(null)
	const [selectedPrice, setSelectedPrice] = useState(
		courseData.acf.reis_informatie_blok.prijs,
	)
	const [twoPersonRoom, setTwoPersonRoom] = useState(true)
	const [ownTrafel, setOwnTrafel] = useState(false)

	useEffect(() => {
		if (twoPersonRoom && ownTrafel) {
			setSelectedPrice(courseData.acf.reis_informatie_blok.prijs)
		}
		if (twoPersonRoom && !ownTrafel) {
			setSelectedPrice(
				courseData.acf.reis_informatie_blok.prijs_inc_vervoer,
			)
		}
		if (!twoPersonRoom && ownTrafel) {
			setSelectedPrice(
				courseData.acf.reis_informatie_blok.prijs_1p_ex_vervoer,
			)
		}

		if (!twoPersonRoom && !ownTrafel) {
			setSelectedPrice(
				courseData.acf.reis_informatie_blok.prijs_1p_inc_vervoer,
			)
		}
	}, [twoPersonRoom, ownTrafel])

	useEffect(() => {
		const canvas = canvasRef.current
		if (!canvas) return
		signaturePad.current = new SignaturePad(canvas)

		function resizeCanvas() {
			const ratio = Math.max(window.devicePixelRatio || 1, 1)
			canvas.width = canvas.offsetWidth * ratio
			canvas.height = canvas.offsetHeight * ratio
			canvas.getContext('2d').scale(ratio, ratio)
			signaturePad.current.clear() // otherwise isEmpty() might return incorrect value
		}

		window.addEventListener('resize', resizeCanvas)
		resizeCanvas()

		return () => {
			window.removeEventListener('resize', resizeCanvas)
			signaturePad.current.off()
		}
	}, [])

	if (!courseData || !courseData.acf) {
		return null
	}

	// const firstTimeTable =
	// 	courseData &&
	// 	courseData.acf.variations &&
	// 	courseData.acf.variations[0] &&
	// 	courseData.acf.variations[0].times
	// 		? courseData.acf.variations[0].times
	// 		: false
	// const [currentVariant, setCurrentVariant] = useState(firstTimeTable)
	// let keyCount = 0
	// let choiceCount = 1
	//
	// function updateTimes(timesTable, event) {
	// 	setCurrentVariant(timesTable)
	// }
	//
	// function createTimeSlotOption(choice, variantTimes = false) {
	// 	let usableDays = days
	// 	if (
	// 		courseData.acf.variations &&
	// 		courseData.acf.variations.length &&
	// 		variantTimes
	// 	) {
	// 		const variantDays =
	// 			variantTimes.body && variantTimes.body.length
	// 				? variantTimes.body
	// 				: []
	//
	// 		const variantHeaders =
	// 			variantTimes.header && variantTimes.header.length
	// 				? variantTimes.header.map((header) => header.c)
	// 				: []
	//
	// 		usableDays = transformTableData(variantDays, variantHeaders)
	// 	}
	//
	// 	return (
	// 		<select name={choice + '-choice'} id={choice + '-choice'}>
	// 			<option disabled hidden>
	// 				{choiceCount && choiceCount++}e keus
	// 			</option>
	// 			{usableDays.length
	// 				? usableDays.map((day, i) => {
	// 						keyCount++
	// 						return day.times.length
	// 							? day.times.map((time) => {
	// 									keyCount++
	// 									if (time !== '-') {
	// 										return (
	// 											<option
	// 												key={`${day.name}-${time}-${i}-${keyCount}`}
	// 												value={`${day.name} - ${time}`}
	// 											>
	// 												{day.name} - {time}
	// 											</option>
	// 										)
	// 									}
	// 							  })
	// 							: null
	// 				  })
	// 				: null}
	// 		</select>
	// 	)
	// }

	return (
		<React.Fragment>
			<section className={'sign-up-form'}>
				{!formSent ? (
					<>
						<article>
							<p>
								Aanmelden{' '}
								{courseData.acf.reis_informatie_blok.reis_titel}
							</p>
							<p>
								Na dit formulier bij ons binnen is sturen wij
								binnenkort een bevestiging van je reservering
								per mail en krijgt u meer informatie over de
								volgende stappen en betaling. Voor vragen en/of
								opmerking kunt u contact opnemen via{' '}
								<a
									target={'_blank'}
									href="mailto:info@inbalansalkmaar.nl"
									rel="noopener noreferrer"
								>
									info@inbalansalkmaar.nl
								</a>{' '}
								of bel{' '}
								<a href="tel:0725200924">072 52 00 924</a>
							</p>
							<Specifications
								specifications={[
									{
										label:
											courseData.acf.reis_informatie_blok
												.datums[0].start_datum,
										icon: (
											<Clock
												winter={HeaderCTX.winterTime}
												color={'#F2F3F4'}
											/>
										),
									},
									{
										label: selectedPrice.toLocaleString(
											'nl-NL',
										),
										icon: <Eur color={'#F2F3F4'} />,
									},
								]}
							/>
						</article>

						<form
							action=""
							id="courseForm"
							onSubmit={(e) => {
								e.preventDefault()

								const formData = new FormData(
									document.querySelector(`#courseForm`),
								)

								const phoneNumber = phoneNumberTransformer(
									formData.get('telefoon'),
								)

								formData.set('telefoon', phoneNumber)

								for (let [name, value] of formData) {
									if (name.includes('geboortedatum')) {
										formData.set(name, convertDate(value))
									}
								}

								formData.append(
									'_wpcf7_unit_tag',
									formData.get('cursus-id'),
								)

								if (
									signaturePad.current &&
									signaturePad.current.isEmpty()
								) {
									alert(
										'Om verder te kunnen gaan moet u uw handtekening invullen.',
									)
									return
								}

								signaturePad.current.canvas.toBlob((blob) => {
									formData.append(
										'handtekening',
										new File([blob], 'signature.png', {
											type: 'image/png',
										}),
									)

									fetch(
										`https://cms.inbalansalkmaar.nl/wp-json/contact-form-7/v1/contact-forms/8564/feedback`,
										{
											method: 'POST', // *GET, POST, PUT, DELETE, etc.
											mode: 'cors', // no-cors, cors, *same-origin
											body: formData,
										},
									).then((response) => {
										if (response.ok) {
											trackCustomEvent({
												// string - required - The object that was interacted with (e.g.video)
												category: 'event',
												// string - required - Type of interaction (e.g. 'play')
												action: 'aanmelding',
												// string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
												label:
													courseData.acf.title_course,
											})
											setFormSent(true)
											alert(
												'Uw aanmelding is verstuurd. Wij zullen zo spoedig mogelijk reageren via email of telefoon.',
											)
										} else {
											alert(
												'Uw aanmelding is niet gelukt. Herlaad de pagina en probeer het nog eens.',
											)
										}
									})
								})
							}}
						>
							<article className={'sign-up-form__column--holder'}>
								<>
									<input
										type="hidden"
										name={'cursus'}
										value={
											courseData.acf.reis_blok.reis_title
										}
									/>
								</>
								<div className={'sign-up-form__column'}>
									<p
										className={
											'sign-up-form__column--title'
										}
									>
										Reis
									</p>
									<p className={'sign-up-form__column--text'}>
										{
											courseData.acf.reis_informatie_blok
												.reis_titel
										}
									</p>
								</div>
								<div className={'sign-up-form__column'}>
									<p
										className={
											'sign-up-form__column--title'
										}
									>
										Kosten
									</p>
									<div>
										<p className="sign-up-form__column--text">
											€
											{selectedPrice.toLocaleString(
												'nl-NL',
											)}
										</p>
										<input
											type="hidden"
											name="cursus-id"
											value={
												courseData.acf.customId
													? courseData.acf.customId
													: courseData.wordpress_id
											}
										/>
									</div>
								</div>
							</article>
							<div className={'sign-up-form__column--holder'}>
								{courseData && courseData.acf ? (
									<fieldset
										className={'sign-up-form__radio-holder'}
									>
										<legend
											className={
												'sign-up-form__radio--title'
											}
										>
											Verblijf in Hotel Kungshaga
										</legend>

										<label
											// htmlFor="two"
											className={
												'sign-up-form__radio--label'
											}
										>
											<input
												className={
													'sign-up-form__radio--radio'
												}
												type="radio"
												id="two"
												name="persoonskamer"
												defaultChecked
												value={'2 persoonskamer'}
												onClick={() => {
													setTwoPersonRoom(true)
												}}
											/>
											<div></div>2 persoonskamer
										</label>
										<label
											// htmlFor="update-sign-up"
											className={
												'sign-up-form__radio--label'
											}
										>
											<input
												className={
													'sign-up-form__radio--radio'
												}
												type="radio"
												id="one"
												name="persoonskamer"
												value={'1 persoonskamer'}
												onClick={() => {
													setTwoPersonRoom(false)
												}}
											/>
											<div></div>1 persoonskamer (toeslag)
										</label>
									</fieldset>
								) : null}

								{courseData && courseData.acf ? (
									<fieldset
										className={'sign-up-form__radio-holder'}
									>
										<legend
											className={
												'sign-up-form__radio--title'
											}
										>
											Vervoer
										</legend>

										<label
											className={
												'sign-up-form__radio--label'
											}
										>
											<input
												className={
													'sign-up-form__radio--radio'
												}
												type="radio"
												name="vervoer"
												value={'Ik regel zelf vervoer'}
												defaultChecked
												onClick={() => {
													setOwnTrafel(true)
												}}
											/>
											<div></div>
											Ik regel zelf vervoer
										</label>
										<label
											className={
												'sign-up-form__radio--label'
											}
										>
											<input
												className={
													'sign-up-form__radio--radio'
												}
												type="radio"
												name="vervoer"
												onClick={() => {
													setOwnTrafel(false)
												}}
												value={
													'Touringcar + Overtocht Stena line'
												}
											/>
											<div></div>
											Touringcar + Overtocht Stena line
										</label>
									</fieldset>
								) : null}

								<fieldset
									className={'sign-up-form__radio-holder'}
								>
									<legend
										className={'sign-up-form__radio--title'}
									>
										Schaatsles
									</legend>

									<label
										className={'sign-up-form__radio--label'}
									>
										<input
											className={
												'sign-up-form__radio--radio'
											}
											type="radio"
											name="schaatsles"
											value={'ik volg schaatslet'}
											defaultChecked
										/>
										<div></div>
										Ik volg schaatsles bij Schaats- en
										Skeelerschool In Balans
									</label>
									<label
										className={'sign-up-form__radio--label'}
									>
										<input
											className={
												'sign-up-form__radio--radio'
											}
											type="radio"
											name="schaatsles"
											value={'ik volg geen schaatslet'}
										/>
										<div></div>
										Ik volg geen schaatsles
									</label>
								</fieldset>

								<fieldset
									className={'sign-up-form__radio-holder'}
								>
									<legend
										className={'sign-up-form__radio--title'}
									>
										Natuurijs
									</legend>

									<label
										className={'sign-up-form__radio--label'}
									>
										<input
											className={
												'sign-up-form__radio--radio'
											}
											type="radio"
											name="natuurijs"
											value={'wel ervaring'}
											defaultChecked
										/>
										<div></div>
										Ik heb WEL ervaring op natuurijs
									</label>
									<label
										className={'sign-up-form__radio--label'}
									>
										<input
											className={
												'sign-up-form__radio--radio'
											}
											type="radio"
											name="natuurijs"
											value={'geen ervaring'}
										/>
										<div></div>
										Ik heb GEEN ervaring op natuurijs
									</label>
								</fieldset>
							</div>
							{/* <fieldset>
								<legend
									className={'sign-up-form__column--title'}
								>
									Schaatsen huren
								</legend>
								<div className={'sign-up-form__select--holder'}>
									<div>
										<label
											htmlFor="experience"
											className={
												'sign-up-form__radio--title'
											}
										>
											Maat
										</label>
										<select name={'rental'} id={'rental'}>
											<option value={'selected'}>
												Geen
											</option>
											{Array.from(Array(20)).map(
												(x, index) => (
													<option
														key={index + 28}
														value={index + 28}
														id={`size_${
															index + 28
														}`}
													>
														{index + 28}
													</option>
												),
											)}
										</select>
									</div>
								</div>
							</fieldset> */}
							{/*<fieldset>*/}
							{/*	<legend className={'sign-up-form__column--title'}>*/}
							{/*		Beschikbare tijden*/}
							{/*	</legend>*/}
							{/*	<div className={'sign-up-form__select--holder'}>*/}
							{/*		<div>*/}
							{/*			<label*/}
							{/*				htmlFor="first-choice"*/}
							{/*				className={'sign-up-form__radio--title'}*/}
							{/*			>*/}
							{/*				1e keus*/}
							{/*			</label>*/}
							{/*			{createTimeSlotOption('first', currentVariant)}*/}
							{/*		</div>*/}
							{/*		<div>*/}
							{/*			<label*/}
							{/*				htmlFor="second-choice"*/}
							{/*				className={'sign-up-form__radio--title'}*/}
							{/*			>*/}
							{/*				2e keus*/}
							{/*			</label>*/}
							{/*			{createTimeSlotOption('second', currentVariant)}*/}
							{/*		</div>*/}
							{/*		<div>*/}
							{/*			<label*/}
							{/*				htmlFor="third-choice"*/}
							{/*				className={'sign-up-form__radio--title'}*/}
							{/*			>*/}
							{/*				3e keus*/}
							{/*			</label>*/}
							{/*			{createTimeSlotOption('third', currentVariant)}*/}
							{/*		</div>*/}
							{/*	</div>*/}
							{/*</fieldset>*/}
							{/*{courseData.acf.tonen.includes('niveautabel') ? (*/}
							{/*	<fieldset>*/}
							{/*		<legend className={'sign-up-form__column--title'}>*/}
							{/*			Ervaring*/}
							{/*		</legend>*/}
							{/*		<div className={'sign-up-form__select--holder'}>*/}
							{/*			<div>*/}
							{/*				<label*/}
							{/*					htmlFor="experience"*/}
							{/*					className={'sign-up-form__radio--title'}*/}
							{/*				>*/}
							{/*					Schaats ervaring*/}
							{/*				</label>*/}
							{/*				<select*/}
							{/*					name={'experience'}*/}
							{/*					id={'experience'}*/}
							{/*				>*/}
							{/*					<option*/}
							{/*						value={'selected'}*/}
							{/*						disabled*/}
							{/*						hidden*/}
							{/*					>*/}
							{/*						Ervaring*/}
							{/*					</option>*/}
							{/*					<option value="1" id={'experience_1'}>*/}
							{/*						Onervaren*/}
							{/*					</option>*/}
							{/*					<option value="2" id={'experience_2'}>*/}
							{/*						Beetje ervaren*/}
							{/*					</option>*/}
							{/*					<option value="3" id={'experience_3'}>*/}
							{/*						Redelijk ervaren*/}
							{/*					</option>*/}
							{/*					<option value="4" id={'experience_4'}>*/}
							{/*						Ervaren*/}
							{/*					</option>*/}
							{/*					<option value="5" id={'experience_5'}>*/}
							{/*						Zeer ervaren*/}
							{/*					</option>*/}
							{/*					<option value="6" id={'experience_6'}>*/}
							{/*						Expert*/}
							{/*					</option>*/}
							{/*				</select>*/}
							{/*			</div>*/}
							{/*		</div>*/}
							{/*	</fieldset>*/}
							{/*) : null}*/}
							{/*{courseData &&*/}
							{/*courseData.acf &&*/}
							{/*courseData.acf.tonen &&*/}
							{/*courseData.acf.tonen.includes('geboortedatum') ? (*/}
							{/*	<fieldset>*/}
							{/*		<legend className={'sign-up-form__column--title'}>*/}
							{/*			Leeftijd*/}
							{/*		</legend>*/}
							{/*		<div className={'sign-up-form__select--holder'}>*/}
							{/*			<div>*/}
							{/*				<label*/}
							{/*					htmlFor="birthdate"*/}
							{/*					className={'sign-up-form__radio--title'}*/}
							{/*				>*/}
							{/*					Geboortedatum*/}
							{/*				</label>*/}
							{/*				<input*/}
							{/*					required*/}
							{/*					type="date"*/}
							{/*					id="birthdate"*/}
							{/*					name="birthdate"*/}
							{/*					value="1990-01-01"*/}
							{/*					min="1915-01-01"*/}
							{/*					max={`${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDay()}`}*/}
							{/*				/>*/}
							{/*			</div>*/}
							{/*		</div>*/}
							{/*	</fieldset>*/}
							{/*) : null}*/}
							{/*{courseData.acf.tonen.includes('eerder-cursus') ? (*/}
							{/*	<fieldset>*/}
							{/*		<legend className={'sign-up-form__column--title'}>*/}
							{/*			Eerdere cursus*/}
							{/*		</legend>*/}
							{/*		<div className={'sign-up-form__text--holder'}>*/}
							{/*			<label*/}
							{/*				htmlFor="eerder-cursus"*/}
							{/*				className={'sign-up-form__radio--title'}*/}
							{/*			>*/}
							{/*				Uw registratienummer*/}
							{/*			</label>*/}
							{/*			<small>*/}
							{/*				Heeft u al eerder een cursus bij In Balans*/}
							{/*				gevolgd? Voor snelle verwerking van dit*/}
							{/*				formulier is uw registratienummer*/}
							{/*				noodzakelijk.*/}
							{/*			</small>*/}
							{/*			<input*/}
							{/*				type="text"*/}
							{/*				id="eerder-cursus"*/}
							{/*				name="eerder-cursus"*/}
							{/*				placeholder={'Uw registratienummer'}*/}
							{/*			/>*/}
							{/*		</div>*/}
							{/*	</fieldset>*/}
							{/*) : null}*/}
							<fieldset>
								<legend
									className={'sign-up-form__column--title'}
								>
									Gegevens deelnemer
								</legend>
								<div className={'sign-up-form__text--holder'}>
									<label
										className={'sign-up-form__radio--title'}
									>
										Voornaam
									</label>
									<input
										required
										type="text"
										name="voornaam"
										placeholder={'Voornaam'}
										minLength={2}
										autoComplete={'given-name'}
									/>
								</div>
								<div
									className={
										'sign-up-form__text--holder sign-up-form__text--holder--paspoort'
									}
								>
									<label
										className={'sign-up-form__radio--title'}
									>
										Namen zoals vermeldt in paspoort
									</label>
									<input
										required
										type="text"
										name="voornaam-als-paspoort"
										placeholder={'Voornaam'}
										minLength={2}
										autoComplete={'given-name'}
									/>
								</div>
								<div className={'sign-up-form__text--holder'}>
									<label
										htmlFor="naam"
										className={'sign-up-form__radio--title'}
									>
										Achternaam
									</label>
									<input
										minLength={2}
										required
										type="text"
										id="naam"
										name="naam"
										placeholder={'Achternaam'}
										autoComplete={'family-name'}
									/>
								</div>
								<div className={'sign-up-form__text--holder'}>
									<label
										htmlFor="email"
										className={'sign-up-form__radio--title'}
									>
										Emailadres
									</label>
									<input
										required
										type="email"
										id="email"
										name="email"
										placeholder={'Emailadres'}
										autoComplete={'email'}
									/>
								</div>
								<div className={'sign-up-form__text--holder'}>
									<label
										htmlFor="straat"
										className={'sign-up-form__radio--title'}
									>
										Straat
									</label>
									<input
										required
										type="text"
										id="straat"
										name="straat"
										placeholder={'Straatnaam'}
										autoComplete={'address-line1'}
									/>
								</div>
								<div className={'sign-up-form__text--holder'}>
									<label
										htmlFor="straat-nummer"
										className={'sign-up-form__radio--title'}
									>
										Huisnummer
									</label>
									<input
										required
										type="text"
										id="straat-nummer"
										name="straat-nummer"
										placeholder={'12A'}
										autoComplete={'address-line2'}
									/>
								</div>
								<div className={'sign-up-form__text--holder'}>
									<label
										htmlFor="postcode-cijfers"
										className={'sign-up-form__radio--title'}
									>
										Postcode
									</label>
									<input
										required
										type="text"
										id="postcode-cijfers"
										name="postcode-cijfers"
										placeholder={'1234'}
										pattern={'[0-9][0-9][0-9][0-9]'}
										maxLength={4}
										minLength={4}
										autoComplete={'postal-code'}
									/>
									<input
										required
										type="text"
										id="postcode-letters"
										name="postcode-letters"
										placeholder={'AA'}
										maxLength={2}
										minLength={2}
										autoComplete={false}
									/>
								</div>
								<div className={'sign-up-form__text--holder'}>
									<label
										htmlFor="place"
										className={'sign-up-form__radio--title'}
									>
										Woonplaats
									</label>
									<input
										required
										type="text"
										id="woonplaats"
										name="woonplaats"
										placeholder={'Alkmaar'}
										autoComplete={'address-level2'}
									/>
								</div>
								<div className={'sign-up-form__text--holder'}>
									<label
										htmlFor="phone"
										className={'sign-up-form__radio--title'}
									>
										Telefoonnummer
									</label>
									<input
										required
										type="text"
										id="telefoon"
										name="telefoon"
										placeholder={'0612345678'}
										autoComplete={'tel'}
									/>
								</div>
								<div className={'sign-up-form__text--holder'}>
									<div>
										<label
											htmlFor="geboortedatum"
											className={
												'sign-up-form__radio--title'
											}
										>
											Geboortedatum
										</label>
										<input
											required
											type="date"
											id="geboortedatum"
											name="geboortedatum"
											placeholder="30-12-1990"
											pattern="\d{2}-\d{2}-\d{4}"
											autoComplete={'bday'}
										/>
									</div>
								</div>
							</fieldset>
							<div
								className={
									'sign-up-form__text--holder signature'
								}
							>
								<div>
									<label
										className={
											'sign-up-form__radio--title signature-pad-label'
										}
									>
										Handtekening
									</label>
									<canvas
										ref={canvasRef}
										className="signature-pad"
									/>
								</div>
								<Button
									action={() => {
										if (!signaturePad.current) return
										signaturePad.current.clear()
									}}
									text={'Opnieuw'}
									className={'primary solid'}
								/>
							</div>
							<Button
								text={'Inschrijven'}
								type={'submit'}
								className={'secondary'}
							/>
						</form>
					</>
				) : (
					<p>
						Uw aanvraag is succesvol verstuurd! U ontvangt van ons
						een email.
					</p>
				)}
			</section>
		</React.Fragment>
	)
}
