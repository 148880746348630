import React, { useContext } from 'react'
import './InfoBlock.scss'
import Title from '../Title/Title'

import Specifications from '../Specifications/Specifications'
import TextInColumns from '../TextInColumns/TextInColumns'
import PagePadding from '../PagePadding/PagePadding'
import Clock from '../../images/icons/Clock'
import Age from '../../images/icons/Age'
import { HeaderContext } from '../Header/Header'
import { formatDate } from '../EventItems/EventItems'

export default (props) => {
	const {
		title,
		information,
		course_description,
		practicalData,
	} = props.course
	const { prijs, aantal_dagen, leeftijd, datums } = practicalData
	const HeaderCTX = useContext(HeaderContext)

	const specs = [
		{
			label: `${aantal_dagen} dagen`,
			icon: <Clock winter={HeaderCTX.winterTime} />,
		},
		{
			label: leeftijd ? `${leeftijd} jaar` : 'Alle leeftijden',
			icon: (
				<Age
					winter={HeaderCTX.winterTime}
					text={leeftijd ? leeftijd : '4'}
				/>
			),
		},
	]

	const data = {
		price: prijs ? prijs : null,
		dates: datums,
	}

	return (
		<PagePadding>
			<div className={'infoblock__holder'}>
				<article className={'infoblock__article'}>
					<Title heading={1} text={title} wave={true} />
					{specs.length ? (
						<Specifications
							specifications={specs}
							extraClass={'columns'}
						/>
					) : null}
					<div>{course_description}</div>
				</article>
				<footer className={'infoblock__info'}>
					<p>Prijs</p>
					<data
						className="price"
						value={data.price ? data.price : 'Nog niet bekend'}
					>
						{data.price
							? 'Vanaf €' + data.price
							: 'Nog niet bekend'}
					</data>
					<p>Data</p>
					{data.dates.length ? (
						<ol>
							{data.dates.map((date) => (
								<li
									key={`${formatDate(
										date.start_datum,
									)} - ${formatDate(date.eind_datum)}`}
								>
									<span>
										{formatDate(date.start_datum)} -{' '}
										{formatDate(date.eind_datum)}
									</span>
									<span>{date.gegarandeerd_of}</span>
								</li>
							))}
						</ol>
					) : null}
				</footer>
			</div>
			<TextInColumns content={information} />
		</PagePadding>
	)
}
