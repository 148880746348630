import React from 'react'
import './Specification.scss'

export default ({ label, icon, keyValue }) => {
	return label !== 'null' ? (
		<li key={keyValue} className="specification__list-item">
			{icon}
			<p className="specification__list-item--text">{label}</p>
		</li>
	) : null
}
